/* eslint-disable prettier/prettier */
<template>
  <div>
    <v-overlay :value="overlay"
      ><v-progress-circular
        :size="70"
        :width="7"
        color="green"
        indeterminate
      ></v-progress-circular
    ></v-overlay>
    <v-container fluid class="px-6 py-6">
      <v-row class="mt-0">
        <v-col cols="12">
          <div>
            <v-card class="card-shadow border-radius-xl">
              <div class="card-header-padding">
                <div class="d-flex align-center">
                  <div>
                    <h5 class="font-weight-bold text-h5 text-typo mb-0">
                      Blend Weigh Up Tasting
                    </h5>
                  </div>
                </div>
              </div>
              <div class="mx-5 mb-5">
                <v-form ref="frm">
                  <v-row>
                    <v-col cols="12" sm="6" md="3" xs="12" lg="3">
                      <label class="text-md text-typo font-weight-bolder ms-1">
                        <span class="red--text"><strong>* </strong></span
                        >Name</label
                      >
                      <v-select
                        :items="blend_names"
                        item-text="name"
                        return-object
                        hide-details="auto"
                        v-model="blend_name"
                        class="
                          input-style
                          font-size-input
                          text-light-input
                          placeholder-light
                          border-radius-md
                          select-style
                          mt-2
                        "
                        outlined
                        height="46"
                        single-line
                        :rules="[(v) => !!v || 'Blend Name is required']"
                        placeholder="Select Blend Name"
                      >
                      </v-select>
                    </v-col>

                    <v-col
                      cols="12"
                      sm="6"
                      md="3"
                      xs="12"
                      lg="3"
                      align-self="center"
                    >
                      <v-btn
                        @click="searchTasting()"
                        elevation="0"
                        :ripple="false"
                        height="46"
                        dark
                        class="
                          font-weight-bold
                          text-capitalize
                          btn-ls btn-primary
                          bg-success
                          px-6
                          mt-7
                        "
                        >Search</v-btn
                      ></v-col
                    >
                  </v-row>
                </v-form>
              </div>
            </v-card>

            <v-card
              class="card-shadow border-radius-xl mt-6"
              id="scroll_1"
              v-show="showTable"
            >
              <v-card-text class="px-0 py-0">
                <v-form ref="frmTable">
                  <v-card-text class="card-padding">
                    <v-data-table
                      v-model="Selection"
                      :headers="tastingHeader"
                      :items="tastingData"
                      item-key="item_name"
                      fixed-header
                      class="table"
                      :footer-props="{
                        'items-per-page-options': [100, 200, 500, -1],
                      }"
                    >
                      <template v-slot:top>
                        <v-toolbar flat>
                          <v-toolbar-title></v-toolbar-title>
                          <v-row
                            ><v-col cols="3">
                              <v-menu
                                ref="mnu_date"
                                v-model="mnu_date"
                                :close-on-content-click="false"
                                :return-value.sync="mnu_date"
                                transition="scale-transition"
                                offset-y
                                min-width="auto"
                              >
                                <template v-slot:activator="{ on, attrs }">
                                  <v-text-field
                                    v-model="date"
                                    readonly
                                    v-bind="attrs"
                                    v-on="on"
                                    hide-details="auto"
                                    class="
                                      input-style
                                      font-size-input
                                      text-light-input
                                      placeholder-light
                                      input-icon
                                      mt-5
                                    "
                                    dense
                                    flat
                                    filled
                                    solo
                                    height="43"
                                    placeholder="Date"
                                    persistent-hint
                                  ></v-text-field>
                                </template>
                                <v-date-picker
                                  color="green lighten-1"
                                  header-color="primary"
                                  v-model="date"
                                  no-title
                                  scrollable
                                >
                                  <v-spacer></v-spacer>
                                  <v-btn
                                    text
                                    color="primary"
                                    @click="mnu_date = false"
                                  >
                                    Cancel
                                  </v-btn>
                                  <v-btn
                                    text
                                    color="primary"
                                    @click="$refs.mnu_date.save(date)"
                                  >
                                    OK
                                  </v-btn>
                                </v-date-picker>
                              </v-menu>
                            </v-col></v-row
                          >
                          <v-spacer></v-spacer>
                          <v-btn
                            elevation="0"
                            :ripple="false"
                            height="46"
                            dark
                            @click="submitTasting"
                            class="
                              font-weight-bold
                              text-capitalize
                              btn-ls btn-primary
                              bg-success
                              py-3
                              px-6
                              mb-5
                              mt-5
                            "
                            >Submit
                          </v-btn>
                        </v-toolbar>
                      </template>

                      <template
                        v-slot:[`item.standard_used_from_week_no`]="{
                          item,
                          index,
                        }"
                      >
                        <div>
                          <v-text-field
                            v-model="item.standard_used_from_week_no"
                            hide-details="auto"
                            class="
                              input-style
                              font-size-input
                              text-light-input
                              placeholder-light
                              input-icon
                              mt-2
                              mb-4
                              centered-input
                            "
                            dense
                            @keyup="changeFocusInput"
                            :ref="`input-${index * 9 + 1}`"
                            flat
                            filled
                            solo
                            height="46"
                            persistent-hint
                          ></v-text-field>
                        </div>
                      </template>
                      <template v-slot:[`item.approved`]="{ item, index }">
                        <div>
                          <v-select
                            v-model.number="item.approved"
                            :items="status"
                            item-text="status"
                            item-value="status"
                            class="
                              input-style
                              font-size-input
                              text-light-input
                              placeholder-light
                              border-radius-md
                              select-style
                              mb-0
                              mt-5
                            "
                            outlined
                            single-line
                            height="46"
                            @keyup="changeFocusInput"
                            :ref="`input-${index * 9 + 7}`"
                          >
                          </v-select>
                        </div>
                      </template>
                      <template
                        v-slot:[`item.below_statndard`]="{ item, index }"
                      >
                        <div>
                          <v-text-field
                            v-model="item.below_statndard"
                            hide-details="auto"
                            class="
                              input-style
                              font-size-input
                              text-light-input
                              placeholder-light
                              input-icon
                              mt-2
                              mb-4
                              centered-input
                            "
                            dense
                            flat
                            filled
                            solo
                            height="46"
                            @keyup="changeFocusInput"
                            :ref="`input-${index * 9 + 8}`"
                            persistent-hint
                          ></v-text-field>
                        </div>
                      </template>
                      <template v-slot:[`item.remarks`]="{ item, index }">
                        <div>
                          <v-text-field
                            v-model="item.remarks"
                            hide-details="auto"
                            class="
                              input-style
                              font-size-input
                              text-light-input
                              placeholder-light
                              input-icon
                              mt-2
                              mb-4
                              centered-input
                            "
                            dense
                            flat
                            filled
                            @keyup="changeFocusInput"
                            :ref="`input-${index * 9 + 9}`"
                            solo
                            height="46"
                            persistent-hint
                          ></v-text-field>
                        </div>
                      </template>

                      <template v-slot:[`item.generated_item_name`]="{ item }">
                        <v-text-field
                          disabled
                          v-model="item.generated_item_name"
                          hide-details="auto"
                          class="
                            input-style
                            font-size-input
                            text-light-input
                            placeholder-light
                            input-icon
                            mt-2
                            mb-4
                            centered-input
                          "
                          dense
                          flat
                          filled
                          solo
                          height="46"
                          persistent-hint
                        ></v-text-field>
                      </template>
                      <template v-slot:[`item.special_marking`]="{ item }">
                        <v-text-field
                          tabindex="-1"
                          v-model="item.special_marking"
                          @input="getItemName(item)"
                          hide-details="auto"
                          class="
                            input-style
                            font-size-input
                            text-light-input
                            placeholder-light
                            input-icon
                            mt-2
                            mb-4
                            centered-input
                          "
                          dense
                          flat
                          filled
                          solo
                          height="46"
                          :value="item.special_marking"
                          persistent-hint
                          @keyup="changeFocusInput"
                          style="width: 100px"
                        ></v-text-field>
                      </template>

                      <template
                        v-slot:[`item.leaf_appearance`]="{ item, index }"
                      >
                        <v-select
                          v-model.number="item.leaf_appearance"
                          :items="leaf_appearance"
                          item-text="value"
                          item-value="value"
                          @change="getItemName(item)"
                          class="
                            input-style
                            font-size-input
                            text-light-input
                            placeholder-light
                            border-radius-md
                            select-style
                            mb-0
                            mt-5
                          "
                          @keyup="changeFocusInput"
                          :ref="`input-${index * 9 + 3}`"
                          outlined
                          single-line
                          height="46"
                        >
                        </v-select>
                      </template>

                      <template v-slot:[`item.leaf_size`]="{ item, index }">
                        <v-select
                          v-model.number="item.leaf_size"
                          :items="leaf_size"
                          item-text="value"
                          item-value="value"
                          @change="getItemName(item)"
                          class="
                            input-style
                            font-size-input
                            text-light-input
                            placeholder-light
                            border-radius-md
                            select-style
                            mb-0
                            mt-5
                          "
                          @keyup="changeFocusInput"
                          :ref="`input-${index * 9 + 2}`"
                          outlined
                          single-line
                          height="46"
                        >
                        </v-select>
                      </template>

                      <template v-slot:[`item.color`]="{ item, index }">
                        <v-select
                          v-model.number="item.color"
                          :items="color"
                          item-text="value"
                          item-value="value"
                          @change="getItemName(item)"
                          @keyup="changeFocusInput"
                          :ref="`input-${index * 9 + 4}`"
                          class="
                            input-style
                            font-size-input
                            text-light-input
                            placeholder-light
                            border-radius-md
                            select-style
                            mb-0
                            mt-5
                          "
                          outlined
                          single-line
                          height="46"
                        >
                        </v-select>
                      </template>
                      <template v-slot:[`item.body`]="{ item, index }">
                        <v-select
                          v-model.number="item.body"
                          :items="body"
                          item-text="value"
                          item-value="value"
                          @change="getItemName(item)"
                          @keyup="changeFocusInput"
                          :ref="`input-${index * 9 + 5}`"
                          class="
                            input-style
                            font-size-input
                            text-light-input
                            placeholder-light
                            border-radius-md
                            select-style
                            mb-0
                            mt-5
                          "
                          outlined
                          single-line
                          height="46"
                        >
                        </v-select>
                      </template>
                      <template v-slot:[`item.taste`]="{ item, index }">
                        <v-select
                          v-model.number="item.taste"
                          @keyup="changeFocusInput"
                          :ref="`input-${index * 9 + 6}`"
                          :items="taste"
                          item-text="value"
                          item-value="value"
                          @change="getItemName(item)"
                          class="
                            input-style
                            font-size-input
                            text-light-input
                            placeholder-light
                            border-radius-md
                            select-style
                            mb-0
                            mt-5
                          "
                          outlined
                          single-line
                          height="46"
                        >
                        </v-select>
                      </template>
                      <template v-slot:[`item.comment`]="{ item }">
                        <v-text-field
                          v-model="item.comment"
                          hide-details="auto"
                          class="
                            input-style
                            font-size-input
                            text-light-input
                            placeholder-light
                            input-icon
                            mt-2
                            mb-4
                            centered-input
                          "
                          dense
                          flat
                          filled
                          solo
                          height="46"
                          :value="item.comment"
                          persistent-hint
                        ></v-text-field>
                      </template>
                    </v-data-table>
                  </v-card-text> </v-form></v-card-text
              ><v-card-action>
                <v-row>
                  <v-col offset-md="5">
                    <v-btn
                      elevation="0"
                      :ripple="false"
                      height="46"
                      dark
                      @click="submitTasting"
                      class="
                        font-weight-bold
                        text-capitalize
                        btn-ls btn-primary
                        bg-success
                        py-3
                        px-6
                        mb-5
                        mt-5
                      "
                      >Submit
                    </v-btn>
                  </v-col>
                </v-row>
              </v-card-action>
            </v-card>

            <!--  -->
            <!--  -->
            <!-- Private Tasting -->
          </div>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import blend from "../../../../api/blend-api";
import api from "../api";
export default {
  data() {
    return {
      leaf_appearance: [],
      leaf_size: [],
      color: [],
      body: [],
      taste: [],
      //
      overlay: false,
      auction_show: false,

      tastingData: [],
      tastingHeader: [
        {
          text: "Blend name",
          width: "100",
          value: "item_name",
          align: "left",
          sortable: false,
        },
        {
          text: "generated name",
          width: "100",
          value: "generated_item_name",
          align: "left",
          sortable: false,
        },
        {
          text: "Appearance",
          width: "100",
          value: "leaf_appearance",
          align: "left",
          sortable: false,
        },
        {
          text: "Size",
          width: "100",
          value: "leaf_size",
          align: "left",
          sortable: false,
        },

        {
          text: "Color",
          width: "100",
          value: "color",
          align: "left",
          sortable: false,
        },
        {
          text: "Body",
          width: "100",
          value: "body",
          align: "left",
          sortable: false,
        },
        {
          text: "Taste",
          width: "100",
          value: "taste",
          align: "left",
          sortable: false,
        },
        {
          text: "Below Standard",
          width: 100,
          value: "below_statndard",
          align: "left",
          class: "text-secondary font-weight-bolder opacity-10",
          sortable: false,
        },
        {
          text: "Remarks",
          width: "100",
          value: "remarks",
          align: "left",
          sortable: false,
        },
        {
          text: "special marking",
          width: "100",
          value: "special_marking",
          align: "left",
          sortable: false,
        },
      ],
      Selection: [],
      show_auction_table: false,

      date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
      mnu_date: false,

      currentItem: 1,
      blend_names: [],
      blend_name: null,
      showTable: false,
    };
  },
  watch: {
    currentItem: {
      handler(value, oldValue) {
        if (value !== oldValue) {
          this.focusInput(value);
        }
      },
      immediate: true,
    },
  },
  methods: {
    getIndex(item) {
      return this.myAllocation.indexOf(item);
    },

    getInput(id) {
      const input = this.$refs[`input-${id}`];
      return input;
    },
    changeFocusInput(event) {
      const key_code = {
        left: 37,
        up: 38,
        right: 39,
        down: 40,
      };

      let nextItem = this.currentItem;

      if (event.keyCode == key_code.left) {
        nextItem -= 1;
      } else if (event.keyCode == key_code.right) {
        nextItem += 1;
      }

      if (this.getInput(nextItem)) {
        this.currentItem = nextItem;
      }
    },
    focusInput(id) {
      const input = this.getInput(id);
      if (input) {
        input.focus();
      }
    },

    async initialize() {
      this.overlay = true;
      this.blend_names = await api.getBlendNames();
      let result = await blend.getBlendfactor();
      this.body = result.body;
      this.leaf_size = result.leaf_size;
      this.color = result.color;
      this.taste = result.taste;
      this.leaf_appearance = result.leaf_appearance;

      this.overlay = false;
    },

    getItemName(item) {
      if (
        item.body > 0 &&
        item.color > 0 &&
        item.leaf_appearance > 0 &&
        item.leaf_size > 0 &&
        item.taste > 0
      ) {
        blend
          .getItemName(
            item.body,
            item.color,
            item.leaf_appearance,
            item.leaf_size,
            item.taste
          )
          .then((res) => {
            item.item_name_1 = res.data.name;
            item.generated_item_name = this.setItemName(item);
          });
      }
    },
    setItemName(item) {
      if (item.special_marking) {
        return `${item.item_name_1}${item.special_marking}`;
      } else {
        return item.item_name_1;
      }
    },
    scrollDown() {
      this.$nextTick(() =>
        document
          .getElementById("scroll_1")
          .scrollIntoView({ behavior: "smooth" })
      );
    },
    scrollDownPrivate() {
      this.$nextTick(() =>
        document
          .getElementById("scroll_2")
          .scrollIntoView({ behavior: "smooth" })
      );
    },
    validate() {
      return this.$refs.frm.validate();
    },
    async searchTasting() {
      if (this.validate()) {
        this.showTable = false;
        this.overlay = true;
        this.tastingData = await api.getTastingData(this.blend_name.id);
        this.Selection = this.tastingData;
        this.overlay = false;
        if (this.tastingData.length > 0) {
          this.showTable = true;
        } else {
          this.noDataAlert("No Data Found...");
          this.showTable = false;
        }
      }
    },
    async submitTasting() {
      try {
        this.overlay = true;
        // console.log("THIS", this.Selection);
        await api.submitTasting(this.Selection);
        this.Selection = [];
        this.tastingData = [];
        this.tastingData = await api.getTastingData(this.blend_name.id);
        this.Selection = this.tastingData;
        this.showSuccessAlert("Data Saved...");
        this.overlay = false;
        // this.initialize();
      } catch (error) {
        this.showErrorAlert("Data couldn't save...");
      }
    },

    showErrorAlert(message) {
      this.$swal({
        title: "Error",
        text: message,
        showConfirmButton: false,
        type: "error",
        timer: 3000,
        icon: "error",
      });
    },
    showSuccessAlert(message) {
      this.$swal({
        title: "Success",
        text: message,
        type: "success",
        timer: 3000,
        icon: "success",
        showConfirmButton: false,
      });
    },
    showWarningAlert(message) {
      this.$swal({
        title: "warning",
        text: message,
        showConfirmButton: false,
        type: "warning",
        timer: 3000,
        icon: "warning",
      });
    },
    noDataAlert(message) {
      this.$swal({
        text: message,

        timer: 3000,
      });
    },
  },
  created() {
    this.initialize();
  },
};
</script>

<style>
.v-data-table {
  overflow-x: auto !important;
}
</style>
