import axios from "axios";

export default {
  async getAuctionSearch(auction_center, season_sale) {
    return await axios
      .get(
        `weigh-up-tasting/auction-search?auction_center=${auction_center}&season_sale=${season_sale}`
      )
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        throw new Error(err.response.data.message);
      });
  },

  async getBlendNames() {
    return await axios
      .get(`blend-tasting/blend-names`)
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        throw new Error(err.response.data.message);
      });
  },

  async getPrivateSearch(season_vendor_offer_id) {
    return await axios
      .get(
        `weigh-up-tasting/private-search?season_vendor_offer_id=${season_vendor_offer_id}`
      )
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        throw new Error(err.response.data.message);
      });
  },

  async saveData(data) {
    return await axios
      .post(`weigh-up-tasting`, data)
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        throw new Error(err.response.data.message);
      });
  },

  async getTastingData(id) {
    return await axios
      .get(`blend-tasting/` + id)
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        throw new Error(err.response.data.message);
      });
  },
  async submitTasting(data) {
    return await axios
      .post(`blend-tasting/`, { data })
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        throw new Error(err.response.data.message);
      });
  },
};
